
import { PREPR_TOPIC_TITLES } from '~/constants'
import { metaDataMixin } from '~/mixins'

export default {
  mixins: [
    metaDataMixin,
  ],
  layout: 'community-visie',
  scrollToTop: false,
  async asyncData ({ $api, $config, store, route }) {
    const [
      { data: { items: articlesHierEnNu = [] } = {} },
      { data: { items: articlesGelovenEnVragen = [] } = {} },
    ] = await Promise.all([
      $api.topics.getAllNested('articles', PREPR_TOPIC_TITLES['hier-en-nu'].id, {
        domain: $config.preprEnvironmentDomainEo,
        limit: 3,
      }),
      $api.topics.getAllNested('articles', PREPR_TOPIC_TITLES['geloven-en-vragen'].id, {
        domain: $config.preprEnvironmentDomainEo,
        limit: 3,
      }),    
    ])
    // Set breadcrumbs for the page
    store.dispatch('breadcrumbs/mirror', {
      path: route.path,
      mirror: `/nevenactiviteit/visie`,
    })

    return { 
      articlesHierEnNu: articlesHierEnNu,
      articlesGelovenEnVragen: articlesGelovenEnVragen,
    }
  },
  data () {
    return {
      articles: [],
      hasMoreAvailable: true,
      seo: {
        title: 'Christelijk nieuws | Achtergronden, opinie en interviews',
        description: 'Volg het laatste christelijke nieuws bij Visie. Hoopvolle artikelen, inclusief achtergronden, opinie en interviews over geloof en samenleving.',
      },
    }
  },
  async fetch () {
    const limit = this.articles.length ? 6 : 9
    const {
      data: {
        items: articles = [],
        meta: {
          total = 0,
        } = {},
      } = {},
    } = await this.$api.articles.getAll({
      domain: this.$config.preprEnvironmentDomainVisie,
      limit,
      offset: this.articles.length,
    })

    this.articles.push(...articles)
    this.hasMoreAvailable = total > this.articles.length
  },
}
